<!--
 * @Author: Libra
 * @Date: 2021-07-06 18:12:18
 * @LastEditTime: 2021-07-06 18:12:27
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/views/invite/index.vue
-->
<template>
  <router-view></router-view>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
